import React from "react";
import "./GetStarted1.css";

const GetStarted1 = () => {
  const handleButtonClick = () => {
    window.location.href = "https://www.swivospace.com/l/draft/00000000-0000-0000-0000-000000000000/new/description";
  };

  return (
    <div className="get-started1-container">
      <h2 className="get-started1-title">Ready to Get Started?</h2>
      <p className="get-started1-text">
        Join our community of space owners and start listing your unique spaces today!
        <br />
        It's quick and easy.
      </p>
      <button className="get-started1-button" onClick={handleButtonClick}>
        List Your Space Now!
      </button>
    </div>
  );
};

export default GetStarted1;