// HowItWorks.js
import React from 'react';
import styles from './HowItWorks.module.css';
import howWorksImage from '../../assets/HowWorks.png'; // Adjusted the path to import the image
import howWorks2Image from '../../assets/HowWorks2.png'; // Adjusted the path to import the image
import howWorks3Image from '../../assets/HowWorks3.png'; // Adjusted the path to import the image



function HowItWorks() {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>How it Works</h2>
      <div className={styles.steps}>
        <div className={styles.step}>
          <img
            src={howWorksImage}

            alt="Showcase Your Space"
            className={styles.image}
          />
          <div className={styles.stepTitle}>Showcase Your Space</div>
          <div className={styles.stepDescription}>
            Take some pictures and showcase your space to prospective event organizers.
          </div>
        </div>
        <div className={styles.step}>
          <img
            src={howWorks2Image}
            alt="Create a Listing"
            className={styles.image}
          />
          <div className={styles.stepTitle}>Create a Listing</div>
          <div className={styles.stepDescription}>
            Set your own terms, pricing, and availability. You maintain complete control over how your space is used.
          </div>
        </div>
        <div className={styles.step}>
          <img
            src={howWorks3Image}
            alt="Start Earning"
            className={styles.image}
          />
          <div className={styles.stepTitle}>Start Earning</div>
          <div className={styles.stepDescription}>
            Connect with prospective event organizers and the community, coordinate bookings, and start earning!
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
