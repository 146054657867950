import React from 'react';
import './EventsLandingPage.css';

const EventsLandingPage = () => {
    return (
        <div className="events-background">
            <div className="events-overlay"></div>
            <div className="events-container">
                <h1 className="events-headline">Find the Perfect Venue</h1>
                <p className="events-subline">For Your Next Unforgettable Event</p>
                <button className="events-book-button"><b>Book Your Space</b></button>
            </div>
        </div>
    );
};

export default EventsLandingPage;