import React, { useEffect, useState } from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field from '../../Field';

import css from './SectionContainer.module.css';
import { useIntl } from 'react-intl';

// This component can be used to wrap some common styles and features of Section-level components.
// E.g: const SectionHero = props => (<SectionContainer><H1>Hello World!</H1></SectionContainer>);
const SectionContainer = props => {
  const { className, rootClassName, id, as, children, appearance, options, ...otherProps } = props;
  const Tag = as || 'section';
  const classes = classNames(rootClassName || css.root, className);

  return (
    <Tag className={classes} id={id} {...otherProps}>
      {appearance?.fieldType === 'customAppearance' ? (
        <Field
          data={{ alt: `Background image for ${id}`, ...appearance }}
          className={className}
          options={options}
        />
      ) : null}

      <div className={css.sectionContent}>{children}</div>
    </Tag>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionContainer.defaultProps = {
  rootClassName: null,
  className: null,
  as: 'div',
  children: null,
  appearance: null,
};

SectionContainer.propTypes = {
  rootClassName: string,
  className: string,
  as: string,
  children: node,
  appearance: object,
  options: propTypeOption,
};

export default SectionContainer;


export const HomeBannerCustomSectionContainer = (props) => {
 const { className, rootClassName, id, as, children, appearance, options, blocks, ...otherProps } = props;
  const Tag = as || 'section';
  const classes = classNames(rootClassName || css.root, className);

  const allBgImages = blocks.map(({media}) => {
   const {backgroundColor, textColor } = appearance;
   if(media.fieldType === "image"){
     const { image: backgroundImage } = media;
     return {
       backgroundColor,
       backgroundImage,
       textColor,
       fieldType: "customAppearance",
     }
   }
 }).filter((each) => each);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const parseIntl = useIntl();
  const duration = parseIntl.formatMessage({
    id: 'HomeBannerCustomSectionContainer.Duration',
  });

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the image index
      setCurrentImageIndex((prevIndex) =>
        (prevIndex + 1) % allBgImages.length
      );
    }, parseInt(duration)); // Change the interval time as per your requirement

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <Tag className={classes} id={id} {...otherProps}>
      {allBgImages.map((appearance, index) => {
        let showResult = index === currentImageIndex;

        return <div 
         key={appearance.backgroundImage.id}
         style={{ 
          visibility: showResult ? 'visible' : 'hidden', 
          opacity: showResult ? "1" : "0", 
          transition: ".2s all ease-in" 
          }}
        >
          <Field
            data={{ alt: `Background image for ${id}`, ...appearance }}
            className={className}
            options={options}
          />
        </div>
      })}
      <div className={css.sectionContent} >{children}</div>
    </Tag>
  );
}
