import React from 'react';
import './landingpagesection.css';  // Importing the CSS file 

const LandingPageSection = () => {
    return (
        <div className="landing-page__container">
            <div className="landing-page__vignette"></div>
            <div className="landing-page__vignette-right"></div>
            <div className="landing-page__overlay">
                <h1 className="landing-page__title">Turn Your Unused Space into Profit with Swivospace</h1>
                <p className="landing-page__description">
                    Join a community of space owners and monetize your property effortlessly. Whether it's an<br />
                    office, studio, or event venue, Swivospace connects you with thousands of potential clients<br />
                    looking for the perfect space.
                </p>
            </div>
            <div className="landing-page__button-container">
                <button 
                    className="landing-page__button"
                    onClick={() => window.location.href = 'https://www.swivospace.com/l/draft/00000000-0000-0000-0000-000000000000/new/description'}
                >
                    <b>List Your Space</b>
                </button>
            </div>
        </div>
    );
};

export default LandingPageSection;
