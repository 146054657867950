import React, { useState } from 'react';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import FieldSelect from '../FieldSelect/FieldSelect';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {
  Button,
  FieldDateRangeInput,
  Form,
  LocationAutocompleteInputField,
  Logo,
} from '../../components';

import css from './HomePageBannerSearch.module.css';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { LINE_ITEM_DAY } from '../../util/types';
import { autocompletePlaceSelected } from '../../util/validators';
import * as validators from '../../util/validators';

const identity = v => v;
const URL_QUERY_PARAM_DATE_FORMAT = 'YYYY-MM-DD';

const HomePageBannerSearchFormComponent = props => {
  const [dates, setDates] = useState({});

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const { handleSubmit, values, invalid, intl, form } = formRenderProps;
        const events = findOptionsForSelectFilter('events', config.custom.filters);
        const eventsLabel = intl.formatMessage({
          id: 'HomeBannerSearchForm.eventLabel',
        });

        const locationFieldLabel = intl.formatMessage({
          id: 'HomeBannerSearchForm.locationLabel',
        });

        const locationFieldPlaceHolder = intl.formatMessage({
          id: 'HomeBannerSearchForm.locationFieldPlaceHolder',
        });

        const dateFieldLabel = intl.formatMessage({
          id: 'HomeBannerSearchForm.dateFieldLabel',
        });

        const eventsDrodownSelectOption = intl.formatMessage({
          id: 'HomeBannerSearchForm.eventsDrodownSelectOption',
        });

        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'HomeBannerSearchForm.addressNotRecognized',
        });

        function handleDateRangeChange(e) {
          const date = {
            startDate: e.startDate,
            endDate: e.endDate,
          };
          setDates(date);
          form.change('dateInputSearch', date);
        }

        return (
          <Form onSubmit={handleSubmit} className={css.form}>
            <div className={css.field}>
              <FieldSelect
                selectClassName={css.fieldSelect}
                id="events"
                name="events"
                label={eventsLabel}
                validate={validators.required('Event is required')}
              >
                <option value="">{eventsDrodownSelectOption}</option>
                {events.map((item, i) => {
                  return (
                    item.key !== 'all_events' ? (
                      <option key={item.key} value={item.key}>
                        {item.label}
                      </option>
                    ) : null
                  );
                })}
              </FieldSelect>
            </div>
            <div className={css.field}>
              <LocationAutocompleteInputField
                className={css.locationAddress}
                iconClassName={css.locationAutocompleteInputIcon}
                predictionsClassName={css.predictionsRoot}
                validClassName={css.validLocation}
                name="location"
                label={locationFieldLabel}
                placeholder={locationFieldPlaceHolder}
                useDefaultPredictions={false}
                valueFromForm={values.location}
                format={identity}
                validate={autocompletePlaceSelected(addressNotRecognizedMessage)}
              />
            </div>
            <div className={css.field}>
              <FieldDateRangeInput
                className={css.fieldDateInput}
                validatorClassName={css.fieldvalidatorClass}
                name="dateInputSearch"
                startDateLabel={dateFieldLabel}
                startDateId="start-id"
                startDatePlaceholderText="Start Date"
                endDateId="end-id"
                unitType={LINE_ITEM_DAY}
                endDateLabel={'-'}
                endDatePlaceholderText="End Date"
                input={{
                  className: 'DateInputWhiteBg',
                  name: 'text',
                  value: dates,
                  onChange: handleDateRangeChange,
                  onBlur: () => {},
                  onFocus: () => {},
                }}
                readOnly
              />
            </div>
            <div className={`${css.field} ${css.actions}`}>
              <label className="hidden">.</label>
              <Button type="submit" disabled={invalid}>
                Search
                {/* <SearchIcon /> */}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

const HomeBannerSearchListingForm = compose(injectIntl)(HomePageBannerSearchFormComponent);
HomeBannerSearchListingForm.displayName = 'HomeBannerSearchListingForm';

function getBoundingBox(boundsObject) {
  const ne = boundsObject.ne;
  const sw = boundsObject.sw;

  return `${ne.lat},${ne.lng},${sw.lat},${sw.lng}`;
}

const HomeBannerSearch = props => {
  const history = useHistory();
  const handleSubmit = values => {
    const { dateInputSearch: dates, location, events } = values;

    let dateValues = {};
    let event = events ? { pub_events: `has_all:${events}` } : {};

    if (dates?.startDate && dates?.endDate) {
      const { startDate, endDate } = dates;
      dateValues.dates = `${moment(startDate).format(URL_QUERY_PARAM_DATE_FORMAT)},${moment(endDate)
        .subtract(1, 'days')
        .format(URL_QUERY_PARAM_DATE_FORMAT)}`;
    }

    const boundsWrap = location?.selectedPlace?.bounds;

    const boundsObj = boundsWrap
      ? {
          bounds: getBoundingBox(boundsWrap).trim(),
          mapSearch: true,
        }
      : {};
    const queryParams = {
      ...event,
      ...dateValues,
      ...boundsObj,
    };

    history.push({
      pathname: '/spaces',
      search: new URLSearchParams(queryParams).toString(),
    });
  };

  return (
    <>
      <div className={css.searchWrapper}>
        <div className={css.logoWrap}>
          <Logo  alt="Swivospace"/>
        </div>
        <HomeBannerSearchListingForm onSubmit={handleSubmit} />
      </div>
      <h1 className={css.highlightText}>
        <FormattedMessage id="HomeBannerSearchForm.FormLabelText" />
      </h1>
    </>
  );
};

export default HomeBannerSearch;
