import React from 'react';
import './ChooseUs.css';
import wideVariety from '../../assets/wide Variety.jpeg';
import easysearch from '../../assets/easy search.jpeg';
import transparent from '../../assets/transparent pricing.jpeg';
import directcom from '../../assets/direct communication.jpeg';
import securebook from '../../assets/secure booking.jpeg';

const ChooseUs = () => {
  return (
    <div className="choose-us-wrapper"> 
      <h2 className="choose-us-title">Why Choose Swivospace?</h2>
      <p className="choose-us-description">
        Swivospace helps event coordinators easily find and book unique venues, simplifying the process with seamless communication and reliable event solutions.
      </p>
      <div className="features-row">
        <div className="feature-item">
          <img src={wideVariety} alt="Wide Variety of Spaces" />
          <p>Wide Variety of Spaces Available</p>
          <span>From elegant ballrooms to quirky outdoor settings, we have options that cater to all tastes.</span>
        </div>
        <div className="feature-item">
          <img src={easysearch} alt="Easy Search and Filter Options" />
          <p>Easy Search and Filter Options</p>
          <span>Quickly narrow down your choices by location, capacity, type, and amenities.</span>
        </div>
        <div className="feature-item">
          <img src={transparent} alt="Transparent Pricing and Availability" />
          <p>Transparent Pricing and Availability</p>
          <span>No hidden fees! View real-time availability and clear pricing upfront.</span>
        </div>
      </div>
      <div className="features-row">
        <div className="feature-item">
          <img src={directcom} alt="Direct Communication with Owners" />
          <p>Direct Communication with Owners</p>
          <span>Get in touch directly with venue owners to ask questions and clarify details.</span>
        </div>
        <div className="feature-item">
          <img src={securebook} alt="Secure Booking Process" />
          <p>Secure Booking Process</p>
          <span>Book with confidence knowing our secure system protects your payment information.</span>
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;