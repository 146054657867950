import React from "react";
import "./GetStarted2.css";

const GetStarted2 = () => {
  const handleButtonClick = () => {
    window.location.href = "https://www.swivospace.com/spaces";
  };

  return (
    <div className="get-started2-container">
      <h2 className="get-started2-title">Ready to Get Started?</h2>
      <p className="get-started2-text">
      Let us help you find the perfect venue for your next event!
        
      </p>
      <button className="get-started2-button" onClick={handleButtonClick}>
        <b>Book Your Perfect Space</b>
      </button>
    </div>
  );
};

export default GetStarted2;